<template>
  <div class="about">
    <h1 class="contentWrapper">About</h1>
    <div class="aboutCopy contentWrapper">
      <p>
        NaturalPoint, Inc. was founded in 1997 to develop and bring to market
        high quality image tracking technology and unique computer control
        devices. Now the largest motion capture provider in the world,
        NaturalPoint offers high-performance optical tracking solutions at the
        most affordable prices in the industry.
      </p>
      <p>
        Based in Corvallis, Oregon, NaturalPoint is the creator of the
        <a href="https://www.optitrack.com">OptiTrack</a>,
        <a href="https://www.naturalpoint.com/trackir/">TrackIR</a> and
        <a href="https://www.naturalpoint.com/smartnav/">SmartNav</a> family of
        brands.
      </p>
    </div>
  </div>
  <!-- END about -->
</template>
<script>
export default {
  name: "About",
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
.about {
  text-align: center;
  background: linear-gradient(180deg, hsla(0, 0%, 9%, 0) 500px, #171717 600px),
    url($images-url + "about-background.jpg");
  background-repeat: no-repeat, no-repeat;
  background-position: 100% 100%, 50% -125px;
  background-size: 100% 100%, 1200px 798px;
  margin-top: -75px;
  padding-top: 75px;
  min-height: 100%;
  z-index: 600;

  @include for-large-up {
    background: linear-gradient(180deg, hsla(0, 0%, 9%, 0) 503px, #171717 630px),
      url($images-url + "about-background.jpg");
    background-repeat: no-repeat, no-repeat;
    background-position: 100% 100%, 50% -225px;
    background-size: 100% 100%, 1700px 1132px;
  }
  @include for-massive-up {
    background: linear-gradient(180deg, hsla(0, 0%, 9%, 0) 675px, #171717 800px),
      url($images-url + "about-background.jpg");
    background-color: #5393d0;
    background-repeat: no-repeat, no-repeat;
    background-position: 100% 100%, 50% -425px, 100% 100%;
    background-size: 100% 100%, 2400px 1530px, 100% 100%;
  }
}
a {
  text-decoration: underline;
  color: rgba(255, 255, 255, 0.5);
}
.aboutCopy {
  padding: 0;
  margin-top: 450px;
  color: rgba(255, 255, 255, 0.5);

  @include for-medium-up {
    padding: 0 40px;
  }
}
</style>
